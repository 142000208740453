@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Scroll Bar CSS */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}

* {
  /* font-family: Georgia, serif !important; */
  /* font-family: "Inter", sans-serif, sans, Georgia !important; */
  font-family: 'Nunito Sans', sans-serif !important;
  /*font-family: 'Poppins', sans-serif 'Archivo' !important;*/

  /* font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important; */

  /* font-optical-sizing: auto; */
  /* font-style: normal; */
}

/* Add this to your global CSS or a CSS module */
.loader {
  border-top-color: transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: 4px;
  border-color: #4b6cb7 transparent transparent transparent;
  width: 2rem;
  height: 2rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
